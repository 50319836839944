import BranchValidator from "./AppSystem/Helpers/BranchValidator";

export const REDUX_PERSIST_KEY = 'Auth-platform';
//const currentBranch = BranchValidator();
const response = await fetch('/branch-info.json');
const data = await response.json();
  const currentBranch = data.branch;

export const API_URL = process.env.NODE_ENV === "development" ? 'https://localhost:7174' : currentBranch === 'master' ? 'https://lrf-kassan-production-b0fahxcggncshrf7.swedencentral-01.azurewebsites.net' :  'https://lrf-kassan-development-hrhqdtetembgfsec.swedencentral-01.azurewebsites.net';

export const APP_URL = process.env.NODE_ENV === "development" ? 'http://localhost:3000' : currentBranch === 'master' ? 'https://kassan.lrf.se' : 'https://kassan-stage.lrf.se';


// PUBLIC PATHS
export const PUBLIC_PATHS = {
  APP: '/',
  LOGINQR: '/login-qr',
  LOGINROTEN: '/ssologin',
  LOGINROTENFROMLRF: '/sso',
  SWEDBANK: '/checkout',
  SWEDBANKPAYMENTCOMPLETED: '/checkout-completed-payment',
  SWEDBANKCOMPLETED: '/checkout-completed',
  SWEDBANKCANCELED: '/checkout-canceled',
  OPENPAYMENTCOMPLETED: '/checkout-openpayment-completed',
  QR_CODE_PUBLIC_INFO: '/qr-code-payment',
  QR_PAYMENT: '/qr-payment',
  REDIRECT_VIEW: '/redirect',
};

// USER PATHS
export const USER_PATHS = {
  APP: '/',
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  NOTIFICATIONS: '/notifications',
  LOGOUT: '/logout',
  SETTINGS:'/settings',
  SALARY_SHEET: '/salary-sheet',
};

// ADMIN PATHS
export const ADMIN_PATHS = {
  ADMIN_USER: '/admin/user-list',
  ADMIN_ORGANIZATION: '/admin/organization-list',
  ADMIN_USER_INFO:'/admin/user-list/user',
  ADMIN_ORGANIZATION_INFO: '/admin/organization-list/organization',
  ADMIN_ROLE: '/admin/role',
  HONORARIUM: '/admin/honorarium-list/honorarium-create',
  HONORARIUM_INFO: '/admin/honorarium-list/honorarium',
  HONORARIUM_LIST: '/admin/honorarium-list',
  ATTEST_LIST_PENDING: '/admin/attest-list-pending',
  ATTEST_LIST_PENDING_PRIMARY: '/admin/attest-list-pending-primary',
  ATTEST_LIST_HISTORY: '/admin/attest-list-history',
  ADMIN_INVOICE_LIST: '/admin/invoice-list',
  ADMIN_INVOICE_CREDIT: '/admin/invoice-list/invoice-credit-create',
  ATTEST_INFO: '/admin/attest-list/attest',
  DIGITAL_ACCOUNT_LIST: '/admin/digital-account-list',
  DIGITAL_ACCOUNT_VIEW:'/admin/digital-account-list/digital-account',
  ACCOUNT_ROW_INFO:'/admin/digital-account-list/digital-account/account-row',
  EXPORT_SIE_DATA: '/admin/export-sie',
  BANK_TRANSFERS_LIST: '/bank-transfer-list',
  BANK_TRANSFERS_INFO: '/bank-transfer-info',
  BANK_TRANSFERS_HISTORY_LIST:'/bank-transfer-list/bank-transfer-history-list',
  INTERNAL_TRANSACTION: '/internal-transaction',
  SALARY_SHEET: '/admin/salary-sheet',
  ADMIN_REPORT_TAX: '/admin/report-tax',
};

// SUPER ADMIN PATHS
export const SUPER_ADMIN_PATHS = {
  TRANSACTION: '/super-admin/transaction-list'
};

// ORGANIZATION PATHS
export const ORGANIZATION_PATHS = {
  ORGANIZATION: '/organization',
  ORG_USERS: '/organization-user-list',
  ORG_USERS_INFO: '/organization-user-list/user',
  DIGITAL_ACCOUNT: '/digital-account',
  ACCOUNT_ROW_INFO:'/digital-account/account-row',
  ORG_SALARY_SHEET: '/org/salary-sheet',
  INVOICE_LIST: '/invoice-list',
  INVOICE_CREATE: '/invoice-list/invoice-create',
  INVOICE_CREDIT_CREATE:'/invoice-list/invoice-credit-create',
  QR_CODE_LIST: '/qr-code-list',
  QR_CODE_CREATE: '/qr-code-list/qr-code-create',
  QR_CODE_INFO: '/qr-code-list/qr-code-info',
};

//REIMBURSEMENT PATHS
export const REPORT_PROCEEDING_PATHS = {
  REPORT_PROCEEDING: '/report-proceeding-list/report-proceeding-create',
  REPORT_PROCEEDING_INFO: '/report-proceeding-list/report-proceeding',
  REPORT_PROCEEDING_LIST: '/report-proceeding-list'
};

// PAY PATHS
export const PAY_PATHS = {
  PAY: '/pay',
  PAY_INFO: '/pay/pay',
  SWEDBANKPAY: '/pay-quickpayment',
  SWEDBANKPAY_INFO: '/pay-quickpayment/pay',
};