import {
    ADMIN_PATHS,
    ORGANIZATION_PATHS, PAY_PATHS,
    PUBLIC_PATHS,
    REPORT_PROCEEDING_PATHS, SUPER_ADMIN_PATHS,
    USER_PATHS
} from './config.js';
import {Dashboard} from "./Common/Views/Dashboard";
import {LoginQR} from "./Public/Views/LoginQR";
import {Profile} from "./Common/Views/Profile";
import { LoginRoten } from './Public/Views/LoginRoten.js';
import { ListNotifications } from './Common/Views/UserNotifications.js';
import { ReportProceeding} from "./Reimbursement/Views/ReportProceeding";
import LogOut from "./Auth/Components/Nav/LogOut";
import ReportProceedingList from "./Reimbursement/Views/ReportProceedingList";
import ReportProceedingInfo from "./Reimbursement/Views/ReportProceedingInfo";
import {Pay} from "./PayReceive/Views/Pay";
import {AdminHonorariumCreate} from "./Honorarium/Views/AdminHonorariumCreate";
import AdminHonorariumList from "./Honorarium/Views/AdminHonorariumList";
import AdminHonorariumInfo from "./Honorarium/Views/AdminHonorariumInfo";
import {Organization} from "./Org/Views/Organization";
import {OrgUsers} from "./Org/Views/OrgUsers";
import {OrgUsersInfo} from "./Org/Views/OrgUsersInfo";
import {AdminUsers} from "./User/Views/AdminUsers";
import {AdminOrganization} from "./Org/Views/AdminOrganization";
import {AdminUserInfo} from "./User/Views/AdminUserInfo";
import {AdminOrganizationInfo} from "./Org/Views/AdminOrganizationInfo";
import {AdminRole} from "./Role/Views/AdminRole";
import {Settings} from "./UserSettings/Views/Settings";
import AdminExportSIE from './ExportSie/Views/AdminExportSIE.js';
import {DigitalAccount} from "./DigitalAccount/Views/DigitalAccount";
import {AdminAttestListPending} from "./Attest/Views/AdminAttestListPending";
import {AdminAttestInfo} from "./Attest/Views/AdminAttestInfo";
import {AccountRowInfo} from "./DigitalAccount/Views/AccountRowInfo";
import {AdminDigitalAccountList} from "./DigitalAccount/Views/AdminDigitalAccountList";
import {AdminDigitalAccountView} from "./DigitalAccount/Views/AdminDigitalAccountView";
import {AdminAccountRowInfo} from "./DigitalAccount/Views/AdminAccountRowInfo";
import {PendingBankTransferList} from "./BankTransfers/Views/PendingBankTransferList";
import {BankTransferHistoryList} from "./BankTransfers/Views/BankTransferHistoryList";
import {PayInfo} from "./PayReceive/Views/PayInfo";
import {AdminInternalTransactionsCreate} from "./InternalTransaction/Views/AdminInternalTransactionsCreate";
import CheckoutPage from './SwedBank/Views/SwedBankPayment.js';
import CheckoutPaymentCompleted from './SwedBank/Views/SwedBankPaymentCompleted.js';
import CheckoutPaymentCanceled from './SwedBank/Views/SwedBankPaymentAbortedByCustomer.js';
import CheckoutOpenPaymentCompleted from './BankTransfers/Views/OpenPaymentCompleted.js';
import { SwedbankPay } from './SwedBank/Views/SwedBankPay.js';
import { SwedBankPayInfo } from './SwedBank/Views/SwedBankPayInfo.js';
import { LoginWithRotenFromLRF } from './Auth/Components/RotenLogin/LoginWithRotenFromLRF.js';
import AdminSalarySheet from './SalarySheet/Views/AdminSalarySheets.js';
import SalarySheet from './SalarySheet/Views/SalarySheet.js';
import AdminReportTaxAuthority from './ReportTaxAuthority/Views/AdminReportTaxAuthority.js';
import {InvoiceList} from "./Invoice/Views/InvoiceList";
import {InvoiceCreate} from "./Invoice/Views/InvoiceCreate";
import {QRCodeList} from "./QR-codes/Views/QRCodeList";
import {QRCodeCreate} from "./QR-codes/Views/QRCodeCreate";
import {QRCodeInfo} from "./QR-codes/Views/QRCodeInfo";
import {QRCodePublicInfo} from "./QR-codes/Views/QRCodePublicInfo";
import OrgQrPayment from "./SwedBank/Views/OrgQrPayment";
import {InvoiceCreditCreate} from "./Invoice/Views/InvoiceCreditCreate";
import CheckoutCompleted from './SwedBank/Views/SwedBankComplete.js';
import { AdminInvoiceList } from './Invoice/Views/AdminInvoiceList.js';
import SuperAdminTransactionList from "./OpenPayment/Views/SuperAdminTransactionList";
import RedirectView from './Common/Views/Redirect.js';
import { PendingBankTransferInfo } from './BankTransfers/Components/PendingBankTransferListInfo.js';
import {AdminAttestListHistory} from "./Attest/Views/AdminAttestListHistory";
import {AdminAttestListPendingPrimary} from "./Attest/Views/AdminAttestListPendingPrimary";

/*************************************************** PUBLIC *****************************************************/
// PUBLIC ROUTES
export const publicRoutes = [
    {
        path: PUBLIC_PATHS.APP,
        component: <LoginQR/>
    },
    {
        path: PUBLIC_PATHS.LOGINQR,
        component: <LoginQR/>
    },
    {
        path: PUBLIC_PATHS.LOGINROTEN,
        component: <LoginRoten/>
    },
    {
        path: PUBLIC_PATHS.LOGINROTENFROMLRF,
        component: <LoginWithRotenFromLRF/>
    },
    {
        path: PUBLIC_PATHS.SWEDBANK,
        component: <CheckoutPage/>
    },
    {
        path: PUBLIC_PATHS.SWEDBANKPAYMENTCOMPLETED,
        component: <CheckoutCompleted/>
    },
    {
        path: PUBLIC_PATHS.SWEDBANKCANCELED,
        component: <CheckoutPaymentCanceled/>
    },
    {
        path: PUBLIC_PATHS.SWEDBANKCOMPLETED,
        component: <CheckoutPaymentCompleted/>
    },
    {
        path: PUBLIC_PATHS.OPENPAYMENTCOMPLETED,
        component: <CheckoutOpenPaymentCompleted/>
    },
    {
        path: PUBLIC_PATHS.QR_CODE_PUBLIC_INFO,
        component: <QRCodePublicInfo/>,
        //name: ' QR-code test',
        hideInMenu: true,
    },
    {
        path: PUBLIC_PATHS.QR_PAYMENT,
        component: <OrgQrPayment/>
    },
    {
        path: PUBLIC_PATHS.REDIRECT_VIEW,
        component: <RedirectView/>
    },
    
];
/*************************************************** PUBLIC *****************************************************/

/*************************************************** ADMIN *****************************************************/
// GLOBAL ADMIN
/*export const adminGlobalRoutes = [
    {
        dropdown: true,
        title: 'adminLabel.admin',
        name: 'adminLabel.admin',
        icon: 'bx bx-face',
        subs: [
            { path: ADMIN_PATHS.ADMIN_USER, label: 'modelLabel.user' },
            { path: ADMIN_PATHS.ADMIN_ORGANIZATION, label: 'modelLabel.org' },
            { path: ADMIN_PATHS.ADMIN_ROLE, label: 'role.roles' },
            { path: ADMIN_PATHS.HONORARIUM_LIST, label: 'honorarium.honorarium' },
            { path: ADMIN_PATHS.EXPORT_SIE_DATA, label: 'exportSieFile.label' },
            { path: ADMIN_PATHS.ATTEST_LIST, label: 'attest.attest' },
            { path: ADMIN_PATHS.DIGITAL_ACCOUNT_LIST, label: 'digitalAccount.label' },
            { path: ADMIN_PATHS.BANK_TRANSFERS_LIST, label: 'openPayment.bankTransfers' },
            { path: ADMIN_PATHS.INTERNAL_TRANSACTION, label: 'internalTransaction.internalTransaction' },
        ],
    }
]*/

// ADMIN ATTEST NORMAL
export const adminAttestRoutes = [
    {
        path: ADMIN_PATHS.ATTEST_LIST_PENDING,
        title: 'adminLabel.admin',
        icon:'bx bxs-calendar-check',
        name: 'sidebar.attest',
        component: <AdminAttestListPending/>,
        //hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.ATTEST_LIST_PENDING_PRIMARY,
        component: <AdminAttestListPendingPrimary/>,
        hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.ATTEST_INFO,
        component: <AdminAttestInfo/>,
        hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.ATTEST_LIST_HISTORY,
        component: <AdminAttestListHistory/>,
        hideInMenu: true,
    }
];
// ADMIN ATTEST WITH PRIMARY COST PLACES
export const adminAttestRoutesPrimary = [
    {
        path: ADMIN_PATHS.ATTEST_LIST_PENDING_PRIMARY,
        title: 'adminLabel.admin',
        icon:'bx bxs-calendar-check',
        name: 'sidebar.attest',
        component: <AdminAttestListPendingPrimary/>,
        //hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.ATTEST_LIST_PENDING,
        component: <AdminAttestListPending/>,
        hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.ATTEST_INFO,
        component: <AdminAttestInfo/>,
        hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.ATTEST_LIST_HISTORY,
        component: <AdminAttestListHistory/>,
        hideInMenu: true,
    }
];

//ADMIN INVOICE
export const adminInvoiceRoutes = [
    {
        path: ADMIN_PATHS.ADMIN_INVOICE_LIST,
        name: 'sidebar.sentInvoices',
        icon: 'bx bxs-file-blank',
        component: <AdminInvoiceList/>,
        //hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.ADMIN_INVOICE_CREDIT,
        component: <InvoiceCreditCreate/>,
        hideInMenu: true,
    },
];

// ADMIN DIGITAL ACCOUNT
export const adminDigitalAccountRoutes = [
    {
        path: ADMIN_PATHS.DIGITAL_ACCOUNT_LIST,
        name: 'sidebar.allAccount',
        icon:'bx bxs-id-card',
        component: <AdminDigitalAccountList/>,
        //hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.DIGITAL_ACCOUNT_VIEW,
        component: <AdminDigitalAccountView/>,
        hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.ACCOUNT_ROW_INFO,
        component: <AdminAccountRowInfo/>,
        hideInMenu: true,
    }
];

// ADMIN HONORARIUM
export const adminHonorariumViewRoutes = [
    {
        path: ADMIN_PATHS.HONORARIUM_LIST,
        name: 'sidebar.honorarium',
        icon:'bx bxs-file',
        component: <AdminHonorariumList/>,
       // hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.HONORARIUM_INFO,
        component: <AdminHonorariumInfo/>,
        hideInMenu: true,
    }
];
export const adminHonorariumCreateRoutes = [
    {
        path: ADMIN_PATHS.HONORARIUM,
        component: <AdminHonorariumCreate/>,
        name: 'sidebar.honorarium',
        icon:'bx bxs-file',
       // hideInMenu: true,
    }
];

// ADMIN ORG
export const adminOrgViewRoutes = [
    {
        path: ADMIN_PATHS.ADMIN_ORGANIZATION,
        title: 'adminLabel.system',
        name:'sidebar.org',
        icon:'bx bxs-building',
        component: <AdminOrganization />,
       // hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.ADMIN_ORGANIZATION_INFO,
        component: <AdminOrganizationInfo/>,
        hideInMenu: true,
    },
];

// ADMIN ROLE
export const adminRoleRoutes = [
    {
        path: ADMIN_PATHS.ADMIN_ROLE,
        name:'sidebar.roles',
        icon:'bx bxs-group',
        component: <AdminRole/>,
        //hideInMenu: true,
    }
];

// ADMIN USER
export const adminUserRoutes = [
    {
        path: ADMIN_PATHS.ADMIN_USER,
        name:'sidebar.user',
        icon:'bx bxs-user',
        component: <AdminUsers/>,
        //hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.ADMIN_USER_INFO,
        component: <AdminUserInfo />,
        hideInMenu: true,
    }
];

// ADMIN SALARY SHEET
export const adminSalaraySheetRoutes = [
    {
        subs: [
            { path: ADMIN_PATHS.SALARY_SHEET, label: 'sidebar.salarySheet' },
        ],
    },
    {
        path: ADMIN_PATHS.SALARY_SHEET,
        //name:'modelLabel.salarySheet',
       // icon:'bx bxs-wallet',
        component: <AdminSalarySheet/>,
        hideInMenu: true,

    }
];

export const adminReportTaxtAuthorityRoutes = [
    {
        subs: [
            { path: ADMIN_PATHS.ADMIN_REPORT_TAX, label: 'sidebar.taxAuthority' },
        ],
    },
    {
        path: ADMIN_PATHS.ADMIN_REPORT_TAX,
        name:'sidebar.taxAuthority',
        //icon:'bx bx-task',
        component: <AdminReportTaxAuthority/>,
        hideInMenu: true,
    }
];

///REPORTS
export const reportsRoutes = [
    {
        dropdown: true,
        // title: 'Collect',
        name: 'sidebar.reports',
        icon: 'bx bx-download',
        subs: [
        ],
    }
]

// ADMIN SIE
export const adminSieRoutes = [
    {
        subs: [
            { path: ADMIN_PATHS.EXPORT_SIE_DATA, label: 'sidebar.exportSie' },
        ],
    },

    {
        path: ADMIN_PATHS.EXPORT_SIE_DATA,
        //title: 'adminLabel.system',
        name:'sidebar.exportSie',
       // icon:'bx bx-download',
        component: <AdminExportSIE/>,
        hideInMenu: true,
    }
];

// ADMIN TRANSFER
export const adminTransferRoutes = [
    {
        path: ADMIN_PATHS.BANK_TRANSFERS_LIST,
        name:'sidebar.bankTransfers',
        icon:'bx bx-transfer',
        component: <PendingBankTransferList/>,
        //hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.BANK_TRANSFERS_HISTORY_LIST,
        component: <BankTransferHistoryList/>,
        hideInMenu: true,
    },
    {
        path: ADMIN_PATHS.BANK_TRANSFERS_INFO,
        component: <PendingBankTransferInfo/>,
        hideInMenu: true,
    },
]

// INTERNAL TRANSACTION
export const adminInternalTransactionRoutes = [
    {
        path: ADMIN_PATHS.INTERNAL_TRANSACTION,
        name:'sidebar.internalTransaction',
        icon:'bx bxs-building-house',
        component: <AdminInternalTransactionsCreate/>,
        //hideInMenu: true,
    }
];
/*************************************************** ADMIN *****************************************************/


/*************************************************** FOR YOUR ORGANIZATION *****************************************************/
// DIGITAL ACCOUNT
export const digitalAccountRoutes = [
    {
        path: ORGANIZATION_PATHS.DIGITAL_ACCOUNT,
        title: 'modelLabel.forYourOrganization',
        component: <DigitalAccount />,
        name: 'digitalAccount.label',
        icon: 'bx bx-id-card',
    },
    {
        path: ORGANIZATION_PATHS.ACCOUNT_ROW_INFO,
        component: <AccountRowInfo/>,
        name: 'sidebar.digitalAccount',
        hideInMenu: true,
    },
];

export const digitalAccossuntRoutes = [
    {
        path: ORGANIZATION_PATHS.DIGITAL_ACCOUNT,
        title: 'modelLabel.forYourOrganization',
        component: <DigitalAccount />,
        name: 'digitalAccount.label',
        icon: 'bx bx-id-card',
    },
    {
        path: ORGANIZATION_PATHS.ACCOUNT_ROW_INFO,
        component: <AccountRowInfo/>,
        name: 'sidebar.digitalAccount',
        hideInMenu: true,
    },
];

// PAY
export const payRoutes = [
    /* {
         dropdown: true,
         title: 'payReceive.payReceiveTitle',
         name: 'payReceive.payReceive',
         icon: 'bx bx-money',
         subs: [
             { path: PAY_PATHS.PAY, label: 'payReceive.pay' },
             { path: PAY_PATHS.SWEDBANKPAY, label: 'payReceive.quickPayment' },
         ],
     },*/
    {
        path: PAY_PATHS.PAY,
        name: 'sidebar.payInvoice',
        component: <Pay/>,
        icon: 'bx bx-money',
        // hideInMenu: true,
    },
    {
        path: PAY_PATHS.PAY_INFO,
        component: <PayInfo/>,
        hideInMenu: true,
    },
    {
        path: PAY_PATHS.SWEDBANKPAY,
        component: <SwedbankPay/>,
        hideInMenu: true,
    },
    {
        path: PAY_PATHS.SWEDBANKPAY_INFO,
        component: <SwedBankPayInfo/>,
        hideInMenu: true,
    }
];

//COLLECT
export const collectRoutes = [
    {
        dropdown: true,
       // title: 'Collect',
        name: 'sidebar.collect',
        icon: 'bx bx-dollar-circle',
        subs: [
        ],
    }
]

//QR-CODE
export const QRcodeView = [
    {
        subs: [
            { path: ORGANIZATION_PATHS.QR_CODE_LIST, label: 'sidebar.QRcode' },
        ],
    },
    {
        path: ORGANIZATION_PATHS.QR_CODE_LIST,
        component: <QRCodeList/>,
        //icon: 'bx bx-qr',
        hideInMenu: true,
    },
    {
        path: ORGANIZATION_PATHS.QR_CODE_INFO,
        component: <QRCodeInfo/>,
        hideInMenu: true,
    },
]

export const QRcodeCreate = [
    {
        path: ORGANIZATION_PATHS.QR_CODE_CREATE,
        component: <QRCodeCreate/>,
        hideInMenu: true,
    },
]

// QUICKPAYMENT
export const quickPaymentRoutes = [
    /* {
         dropdown: true,
         title: 'payReceive.payReceiveTitle',
         name: 'payReceive.payReceive',
         icon: 'bx bx-money',
         subs: [
             { path: PAY_PATHS.PAY, label: 'payReceive.pay' },
             { path: PAY_PATHS.SWEDBANKPAY, label: 'payReceive.quickPayment' },
         ],
     },*/
    {
        subs: [
            { path: PAY_PATHS.SWEDBANKPAY, label: 'sidebar.quickPayments' },
        ],
    },
    {
        path: PAY_PATHS.PAY_INFO,
        component: <PayInfo/>,
        hideInMenu: true,
    },
    {
        path: PAY_PATHS.SWEDBANKPAY,
        name: 'sidebar.quickPayments',
        component: <SwedbankPay/>,
        //icon: 'bx bx-dollar-circle',
         hideInMenu: true,
    },
    {
        path: PAY_PATHS.SWEDBANKPAY_INFO,
        component: <SwedBankPayInfo/>,
        hideInMenu: true,
    }
];

// ORG
export const orgRoutes = [
    {
        path: ORGANIZATION_PATHS.ORG_USERS,
        component: <OrgUsers />,
        name: 'sidebar.orgUsers',
        icon: 'bx bx-user',
    },
    {
        path: ORGANIZATION_PATHS.ORG_USERS_INFO,
        component: <OrgUsersInfo />,
        hideInMenu: true,
    },
    {
        path: ORGANIZATION_PATHS.ORGANIZATION,
        //title: 'modelLabel.org',
        component: <Organization />,
        name: 'sidebar.orgDetails',
        icon: 'bx bxs-school',
    },
];
// REIMBURSEMENT
/*export const orgSalarySheet = [
    {
        path: ORGANIZATION_PATHS.ORG_SALARY_SHEET,
        component: <OrgSalarySheet/>,
        name: 'modelLabel.salarySheet',
        icon: 'bx bx-wallet'
    }
];*/

//INVOICE
export const invoiceCreate = [
    {
        path: ORGANIZATION_PATHS.INVOICE_CREATE,
        component: <InvoiceCreate/>,
        hideInMenu: true,
        //name: 'invoice.invoice',
        //icon: 'bx bx-file-blank',
    },
    {
        path: ORGANIZATION_PATHS.INVOICE_CREDIT_CREATE,
        component: <InvoiceCreditCreate/>,
        hideInMenu: true,
        //name: 'invoice.invoice',
        //icon: 'bx bx-file-blank',
    },
];
export const invoiceView = [
    {
        subs: [
            { path: ORGANIZATION_PATHS.INVOICE_LIST, label: 'sidebar.invoice' },
        ],
    },
    {
        path: ORGANIZATION_PATHS.INVOICE_LIST,
        component: <InvoiceList/>,
        hideInMenu: true,
        //name: 'invoice.invoice',
       //icon: 'bx bx-file-blank',
    }
];


/*************************************************** FOR YOUR ORGANIZATION *****************************************************/


/*************************************************** USER *****************************************************/
// USER
export const userRoutes = [
    {
        path: USER_PATHS.APP,
        component: <Dashboard />,
        hideInMenu: true,
    },
    {
        path: PUBLIC_PATHS.LOGINROTEN,
        component: <LoginRoten/>,
        hideInMenu: true,
    },
    {
        path: PUBLIC_PATHS.LOGINROTENFROMLRF,
        component: <LoginWithRotenFromLRF/>,
        hideInMenu: true,
    },
    {
        path: USER_PATHS.DASHBOARD,
        component: <Dashboard />,
        hideInMenu: true,
    },
    {
        path: USER_PATHS.PROFILE,
        component: <Profile />,
        hideInMenu: true,
    },
    {
        path: USER_PATHS.NOTIFICATIONS,
        component: <ListNotifications />,
        hideInMenu: true
    },
    {
        path: USER_PATHS.LOGOUT,
        component: <LogOut />,
        hideInMenu: true
    },
    {
        path: USER_PATHS.SETTINGS,
        component: <Settings/>,
        hideInMenu: true,
    },
    {
        path: PUBLIC_PATHS.SWEDBANK,
        component: <CheckoutPage/>,
        hideInMenu: true,
    },
    {
        path: PUBLIC_PATHS.SWEDBANKCANCELED,
        component: <CheckoutPaymentCanceled/>,
        hideInMenu: true,
    },
    {
        path: PUBLIC_PATHS.SWEDBANKCOMPLETED,
        component: <CheckoutPaymentCompleted/>,
        hideInMenu: true,
    },
    {
        path: PUBLIC_PATHS.OPENPAYMENTCOMPLETED,
        component: <CheckoutOpenPaymentCompleted/>,
        hideInMenu: true,
    },
    {
        path: PUBLIC_PATHS.QR_CODE_PUBLIC_INFO,
        component: <QRCodePublicInfo/>,
        //name: ' QR-code test',
        hideInMenu: true,
    },
    {
        path: PUBLIC_PATHS.QR_PAYMENT,
        component: <OrgQrPayment/>,
        hideInMenu: true,
    },
    {
        path: PUBLIC_PATHS.REDIRECT_VIEW,
        component: <RedirectView/>,
        hideInMenu: true,
    }
];

// REIMBURSEMENT
export const reimbursementCreateRoutes = [
    {
        path: REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING,
        title: 'modelLabel.forYou',
        component: <ReportProceeding/>,
        name: 'sidebar.reimbursement',
        icon: 'bx bx-receipt'
    }
];
export const reimbursementViewRoutes = [
    {
        path: REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST,
        title: 'modelLabel.forYou',
        component: <ReportProceedingList/>,
        name: 'sidebar.reimbursement',
        icon: 'bx bx-receipt',
    },
    {
        path: REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_INFO,
        component: <ReportProceedingInfo/>,
        hideInMenu: true,
    },
];


export const salarySheet = [
    {
        path: USER_PATHS.SALARY_SHEET,
        component: <SalarySheet/>,
        name: 'sidebar.salarySheet',
        icon: 'bx bx-wallet'
    }
];
/*************************************************** USER *****************************************************/


/*************************************************** SUPER ADMIN *****************************************************/
// SUPER ADMIN
export const transactionRoutes = [
    {
        path: SUPER_ADMIN_PATHS.TRANSACTION,
        component: <SuperAdminTransactionList/>,
        title: 'adminLabel.systemAdmin',
        name: 'sidebar.OPtransaction',
        icon: 'bx bx-transfer'
    }
];
/*************************************************** SUPER ADMIN *****************************************************/