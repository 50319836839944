import {
    adminAttestRoutes, adminAttestRoutesPrimary,
    adminDigitalAccountRoutes,
    adminHonorariumCreateRoutes,
    adminHonorariumViewRoutes,
    adminInternalTransactionRoutes,
    adminInvoiceRoutes,
    adminOrgViewRoutes,
    adminReportTaxtAuthorityRoutes,
    adminRoleRoutes,
    adminSalaraySheetRoutes,
    adminSieRoutes,
    adminTransferRoutes,
    adminUserRoutes, collectRoutes,
    digitalAccountRoutes,
    invoiceCreate,
    invoiceView,
    orgRoutes,
    payRoutes,
    publicRoutes,
    QRcodeCreate,
    QRcodeView,
    quickPaymentRoutes,
    reimbursementCreateRoutes,
    reimbursementViewRoutes, reportsRoutes,
    salarySheet,
    transactionRoutes,
    userRoutes,
} from '../../routes';
import {useSelector} from "react-redux";
import {CheckUserPermission} from "../Helpers/CheckUserPermission";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../Helpers/CheckUserRole";
import {CheckUserOrganization} from "../Helpers/CheckUserOrganization";
import { useTimer } from '../../Common/Helpers/CalculateTimeRemaining';
import { useDispatch } from 'react-redux';
import {useEffect, useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";

// DEFINE ROUTES
export function DefineRoutes() {
    const { tokenData, refreshTokenData, isAuthenticated, orgData, userData } = useSelector(state => state.auth);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // DEFAULT ROUTES: PUBLIC ROUTES
    let routes = publicRoutes;
    // let subs = collectRoutes[0].subs;

    useTimer(refreshTokenData?.expires,tokenData,dispatch);

    // GET USER COST PLACES
    const [apiResponseUserCostPlaceList, setApiResponseUserCostPlaceList] = useState(null);
    const { ApiCall } = useApiCallService();
    useEffect(() => {
        const handleUserCostPlaceList  = (response) => {
            setApiResponseUserCostPlaceList(response);
        };
        const GetUserCostPlaceList = async () => {
            if (userData?.id) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/usersetting/primary-costplace?id=" + userData?.id,
                    };
                    await ApiCall(apiCallOptions, handleUserCostPlaceList);
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetUserCostPlaceList();
    }, [userData]);

    // IS AUTHENTICATED
    if (isAuthenticated === true) {
        // REPLACE DEFAULT ROUTES FOR USER ROUTES
        routes = userRoutes;

        //// USER
        // REIMBURSEMENT
        let reimbursementRoutes = [];
        if (CheckUserPermission("reimbursement") || (CheckUserPermission("reimbursement.create") && CheckUserPermission("reimbursement.view"))) {
            const createRoute = reimbursementCreateRoutes[0];
            createRoute.hideInMenu = true;
            const viewRoute = reimbursementViewRoutes;
            viewRoute.push(createRoute);
            reimbursementRoutes = [...reimbursementRoutes, ...viewRoute];
        } else if (CheckUserPermission("reimbursement.create") && !CheckUserPermission("reimbursement.view")) {
            reimbursementRoutes = [...reimbursementRoutes, ...reimbursementCreateRoutes];
        } else if (CheckUserPermission("reimbursement.view") && CheckUserPermission("reimbursement.create")) {
            const createRoute = reimbursementCreateRoutes[0];
            createRoute.hideInMenu = true;
            const viewRoute = reimbursementViewRoutes;
            viewRoute.push(createRoute);
            reimbursementRoutes = [...reimbursementRoutes, ...viewRoute];
        } else if (CheckUserPermission("reimbursement.view") && !CheckUserPermission("reimbursement.create")) {
            reimbursementRoutes = [...reimbursementRoutes, ...reimbursementViewRoutes];
        }

        if (reimbursementRoutes.length > 0) {
            routes = [...routes, ...reimbursementRoutes];
        }

        // SALARYSHEET
        if (CheckUserPermission("salary-sheet") || CheckUserPermission("salary-sheet.view")) {
            routes = [...routes, ...salarySheet];
        }

        //// ORG
        if (CheckUserOrganization()) {
            // ACCOUNT
            if (CheckUserPermission("account") || CheckUserPermission("account.view")) {
                routes = [...routes, ...digitalAccountRoutes];
            }

            // PAY
            if (CheckUserPermission("pay") || CheckUserPermission("pay.create") || CheckUserPermission("pay.view")) {
                routes = [...routes, ...payRoutes];
            }

            //// COLLECT
            let collectR = collectRoutes;
            // QUICKPAYMENT
            if (CheckUserPermission("quick-payment") || (CheckUserPermission("quick-payment.view") && CheckUserPermission("quick-payment.create"))) {
                collectR[0].subs.push(...quickPaymentRoutes[0].subs);
                collectR.push(quickPaymentRoutes[1]);
                collectR.push(quickPaymentRoutes[2]);
                collectR.push(quickPaymentRoutes[3]);
            } else if(CheckUserPermission("quick-payment.view")) {
                collectR[0].subs.push(...quickPaymentRoutes[0].subs);
                collectR.push(quickPaymentRoutes[1]);
                collectR.push(quickPaymentRoutes[3]);
            }

            // ORG-QR
            if (CheckUserPermission("org-qr") || (CheckUserPermission("org-qr.create") && CheckUserPermission("org-qr.view") && CheckUserPermission("org-qr.update"))) {
                collectR[0].subs.push(...QRcodeView[0].subs);
                collectR.push(QRcodeView[1]);
                collectR.push(QRcodeView[2]);
                collectR.push(QRcodeCreate[0]);
            } else if (CheckUserPermission("org-qr.view")) {
                collectR[0].subs.push(...QRcodeView[0].subs);
                collectR.push(QRcodeView[1]);
                collectR.push(QRcodeView[2]);
            }

            // INVOICE
            if (CheckUserPermission("invoice") || (CheckUserPermission("invoice.create") && CheckUserPermission("invoice.view") && CheckUserPermission("invoice.update"))) {
                collectR[0].subs.push(...invoiceView[0].subs);
                collectR.push(invoiceView[1]);
                collectR.push(invoiceCreate[0]);
                collectR.push(invoiceCreate[1]);
            } else if (CheckUserPermission("invoice.view")) {
                collectR[0].subs.push(...invoiceView[0].subs);
                collectR.push(invoiceView[1]);
            }

            const uniqueSubs = collectR[0].subs.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.path === value.path &&
                    t.label === value.label &&
                    t.component === value.component &&
                    t.hideInMenu === value.hideInMenu
                ))
            );
            collectR[0].subs = uniqueSubs;

            const uniqueArray = collectR.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.path === value.path &&
                    t.component === value.component &&
                    t.hideInMenu === value.hideInMenu
                ))
            );

            collectR = uniqueArray;
            if (collectR.length > 0) {
                routes = [...routes, ...collectR];
            }

            // ORG
            if (CheckUserPermission("org") || CheckUserPermission("org.view")) {
                routes = [...routes, ...orgRoutes];
            }
        }

        // CHECK ADMIN ROLE
        /*if (CheckUserRole("SuperAdmin") || CheckUserRole("Admin") || CheckUserRole("Support") || CheckUserRole("OPEnhet") || CheckUserRole("Företrädare") || CheckUserRole("Ekonomi")) {*/
            let adminRoutes = [];

            // ATTEST
            if (CheckUserPermission("admin") || CheckUserPermission("admin.attest") || CheckUserPermission("admin.attest.view")) {
                if (apiResponseUserCostPlaceList && apiResponseUserCostPlaceList.primaryCostPlaces) {
                    adminRoutes = [...adminRoutes, ...adminAttestRoutesPrimary];
                } else {
                    adminRoutes = [...adminRoutes, ...adminAttestRoutes];
                }
            }

            // DIGITAL ACCOUNT
            if (CheckUserPermission("admin") || CheckUserPermission("admin.account") || CheckUserPermission("admin.account.view")) {
                adminRoutes = [...adminRoutes, ...adminDigitalAccountRoutes];
            }

            // INTERNAL TRANSACTION
            if (CheckUserPermission("admin") || CheckUserPermission("admin.internal-transaction") || CheckUserPermission("admin.internal-transaction.create")) {
                adminRoutes = [...adminRoutes, ...adminInternalTransactionRoutes];
            }

            // TRANSACTIONS
            if (CheckUserPermission("admin") || CheckUserPermission("admin.open-payment") || CheckUserPermission("admin.open-payment.view")) {
                // FOR NOW WORKING WITH "open-payment" permisssion, but need be updated
                adminRoutes = [...adminRoutes, ...adminTransferRoutes];
            }

            // INVOICE
            if (CheckUserPermission("admin") || CheckUserPermission("admin.invoice") || CheckUserPermission("admin.invoice.view")) {
                adminRoutes = [...adminRoutes, ...adminInvoiceRoutes];
            }

            // HONORARIUM
            let honorariumRoutes = [];
            if (CheckUserPermission("admin") || CheckUserPermission("admin.honorarium") || (CheckUserPermission("admin.honorarium.create") && CheckUserPermission("admin.honorarium.view") && CheckUserPermission("admin.honorarium.update"))) {
                const createRoute = adminHonorariumCreateRoutes[0];
                createRoute.hideInMenu = true;
                const viewRoute = adminHonorariumViewRoutes;
                viewRoute.push(createRoute);
                honorariumRoutes = [...honorariumRoutes, ...viewRoute];
            } else if (CheckUserPermission("admin.honorarium.create") && !CheckUserPermission("admin.honorarium.view")) {
                honorariumRoutes = [...honorariumRoutes, ...adminHonorariumCreateRoutes];
            } else if ((CheckUserPermission("admin.honorarium.view") && CheckUserPermission("admin.honorarium.update")) || (CheckUserPermission("admin.honorarium.view") && CheckUserPermission("admin.honorarium.create"))) {
                const createRoute = adminHonorariumCreateRoutes[0];
                createRoute.hideInMenu = true;
                const viewRoute = adminHonorariumViewRoutes;
                viewRoute.push(createRoute);
                honorariumRoutes = [...honorariumRoutes, ...viewRoute];
            } else if (CheckUserPermission("admin.honorarium.view") && !CheckUserPermission("admin.honorarium.create")) {
                honorariumRoutes = [...honorariumRoutes, ...adminHonorariumViewRoutes];
            }

            if (honorariumRoutes.length > 0) {
                adminRoutes = [...adminRoutes, ...honorariumRoutes];
            }

            ///// REPORTS
            let reports = reportsRoutes;

            // REPORT TAX AUTHORITY
            if (CheckUserPermission("admin") || CheckUserPermission("admin.tax-authority") || CheckUserPermission("admin.tax-authority.view")) {
                reports[0].subs.push(...adminReportTaxtAuthorityRoutes[0].subs);
                reports.push(adminReportTaxtAuthorityRoutes[1]);
            }

            // EXPORT SIE
            if (CheckUserPermission("admin") || CheckUserPermission("admin.sie")) {
                reports[0].subs.push(...adminSieRoutes[0].subs);
                reports.push(adminSieRoutes[1]);
            }

            // SALARY SHEET
            if (CheckUserPermission("admin") || CheckUserPermission("admin.salary-sheet") || CheckUserPermission("admin.salary-sheet.view")) {
                reports[0].subs.push(...adminSalaraySheetRoutes[0].subs);
                reports.push(adminSalaraySheetRoutes[1]);
            }

            const uniqueSubsReports = reports[0].subs.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.path === value.path &&
                        t.label === value.label &&
                        t.component === value.component &&
                        t.hideInMenu === value.hideInMenu
                    ))
            );
            reports[0].subs = uniqueSubsReports;

            const uniqueArrayReports = reports.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.path === value.path &&
                        t.component === value.component &&
                        t.hideInMenu === value.hideInMenu
                    ))
            );

            reports = uniqueArrayReports;
            if (reports.length > 0) {
                adminRoutes = [...adminRoutes, ...reports];
            }

            //////// SYSTEM
            // ORG
            if (CheckUserPermission("admin") || CheckUserPermission("admin.org") || CheckUserPermission("admin.org.view") ) {
                adminRoutes = [...adminRoutes, ...adminOrgViewRoutes];
            }

            // USER
            if (CheckUserPermission("admin") || CheckUserPermission("admin.user") || CheckUserPermission("admin.user.view")) {
                adminRoutes = [...adminRoutes, ...adminUserRoutes];
            }

            // ROLE
            if (CheckUserPermission("admin") || CheckUserPermission("admin.role") || CheckUserPermission("admin.role.create") || CheckUserPermission("admin.role.update") || CheckUserPermission("admin.role.view")) {
                adminRoutes = [...adminRoutes, ...adminRoleRoutes];
            }

            if (adminRoutes.length > 0) {
                routes = [...routes, ...adminRoutes];
            }
        //}
        // SUPER ADMIN
        if (CheckUserRole("SuperAdmin")) {
            if (CheckUserPermission("admin") || CheckUserPermission("admin.open-payment") || CheckUserPermission("admin.open-payment.account") || CheckUserPermission("admin.open-payment.consent")) {
                routes = [...routes, ...transactionRoutes];
            }
        }
    }

    return routes.map(route => ({
        ...route,
        title: route.title ? t(route.title) : null,
        name: route.name ? t(route.name) : '',
        subs: route.subs ? route.subs.map(sub => ({
            ...sub,
            label: sub.label ? t(sub.label) : ''
        })) : []
    }));
}