import React, {useEffect, useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {Button, Col, Row, Spinner} from "reactstrap";
import {useTranslation} from "react-i18next";
import { CustomModal } from "../../Common/Components/Modal/CustomModal";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from "react-redux";
import { ChangeDateFormat } from "../../Common/Helpers/ChangeDateFormat";
import {CustomDateLocale} from "../../Common/Helpers/CustomDateLocale";
import SortableList from "../../Common/Components/Lists/SortableList";
import ResponseSieModel from "../Models/ResponseSieModel";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import $ from 'jquery';
import i18next from "i18next";
import {LoadLocalStorage} from "../../Common/Helpers/LoadLocalStorage";
import i18n from "i18next";

const AdminExportSIE = () => {
    const defaultStartDate = new Date();
    defaultStartDate.setHours(0);
    defaultStartDate.setMinutes(1);
    defaultStartDate.setSeconds(0);
    defaultStartDate.setMilliseconds(0);
    const defaultEndDate = new Date();
    defaultEndDate.setHours(23);
    defaultEndDate.setMinutes(59);
    defaultEndDate.setSeconds(59);
    defaultEndDate.setMilliseconds(0);
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [showPicker, setShowPicker] = useState(false);
    const [showError, setShowError] = useState(false);
    const [items, setItems] = useState([]);
    const [showConfirmModalDelete, setShowConfirmModalDelete] = useState(false);
    const [showPreviewCard, setShowPreviewCard] = useState(false);
    const {userData} = useSelector(state => state.auth);
    const today = new Date();
    //const [checkPreview, setCheckPreview] = useState(false);

    const [executeEffect, setExecuteEffect] = useState(false);

    useEffect(() => {
        if (i18next.language !== LoadLocalStorage('selectedLanguage')){
            i18n.changeLanguage(LoadLocalStorage('selectedLanguage'));
        }
        if (executeEffect === false) {
            setExecuteEffect(true);
        }
    }, [executeEffect]);

    const handleApiResponse  = (apiResponse) => {
        setStartDate(defaultStartDate)
        setEndDate(null)
        if (apiResponse !== null && apiResponse !== undefined) {
            let file;
            let fileName = '';
            
            if (apiResponse instanceof Blob) {                
                file = apiResponse;
            } 
            else {
                file = new Blob([apiResponse]);
            } 

            let type = file.type.split("/")
            let extension = type[1]

            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, '0');
            const day = now.getDate().toString().padStart(2, '0');
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');
            
            if (extension === 'si') {
                fileName = `${year}${month}${day}_${hours}${minutes}${seconds}file.${extension}`;
            } 
            else {
                fileName = `${year}${month}${day}_${hours}${minutes}${seconds}file.si`;
            }
        
            const urlToFile = URL.createObjectURL(file);                    
            const link = document.createElement('a');
            link.href = urlToFile;
            link.setAttribute('download', fileName);                    
            document.body.appendChild(link);
            link.click();                   
            document.body.removeChild(link);   
        } 
        else{
            setShowError(true);
        }                    
    };
    const [preview, setPreview] = useState('');
    const [formated, setFormated] = useState(false);

    const [newFormat,setFormat] = useState('');
    useEffect(() => {
        if (formated === false) {
            if (preview !== null && preview !== '') {
                $("#spinner").addClass("d-none");
                $("#contentPreview").removeClass("d-none");
                setFormat(preview.replace(/#/g, '<br>#').replace(/#FLAGGA 0/g, '#FLAGGA 0 <br>').replace(/#VALUTA SEK/g, '#VALUTA SEK <br>').replace(/#VER/g, '<div style="padding-top: 20px;">#VER').replace(/}#/g, '</div>}<br>#'));
                setFormated(true);
            } else {
                $("#spinner").addClass("d-none");
               $("#contentPreview").removeClass("d-none");
                setFormat('');
                setPreview('');
                setFormated(true)
            }
        }
    }, [preview, formated]);
    
    const ExportData = async () => {
        setShowPreviewCard(false);
        let url = `/export-data?fromDateStr=${ChangeDateFormat('dateTime', startDate)}&toDateStr=${ChangeDateFormat('dateTime', endDate)}`
        const apiCallOptions = {
            method: "get",
            url: url,
        };
        try {
            await ApiCall(apiCallOptions, handleApiResponse);
            setStartDate(defaultStartDate)
            setEndDate(null)
        } catch (error) {
            console.log(error);
        }
    };


    const previewData = async () => {
       // setShowPreviewCard(false);
       // setCheckPreview(true);
        $("#cardPreview").removeClass("d-none");
        $("#spinner").removeClass("d-none");
        $("#contentPreview").addClass("d-none");
        let url = `/export-data?fromDateStr=${ChangeDateFormat('dateTime', startDate)}&toDateStr=${ChangeDateFormat('dateTime', endDate)}`
        const apiCallOptions = {
            method: "get",
            url: url,
        };
        try {
            await ApiCall(apiCallOptions, setPreview);
            setFormated(false);
        } catch (error) {
            console.log(error);
           // setShowSpinner(false);
           $("#spinner").addClass("d-none");
            $("#contentPreview").removeClass("d-none");

        }
    };


  /*  const handleApiResponseAccountRowList = (apiResponse) =>{
        if (apiResponse !== null && apiResponse !== undefined) {
            setItems(apiResponse); 
            setShowPreviewCard(true);
        }
        else{
            setShowError(true);
        }
    }
    const GetAccountRows = async () => {
       let url = `/digital-account/admin/account-row/list?fromDate=${ChangeDateFormat('dateTime', startDate)}&toDate=${ChangeDateFormat('dateTime', endDate)}`;
        const apiCallOptions = {
            method: "get",
            url: url,
        };
        try {
            await ApiCall(apiCallOptions, handleApiResponseAccountRowList); //handleApiResponseAccountRowList
        } catch (error) {
            console.log(error);
        }
    };*/

    const contentBodyDelete = (
        <div>{t('exportSieFile.moddelExportDocument')}</div>    
    );

    const contentFooterDelete = (
        <>
        <Button className="button-bg-grow border-0" onClick={() => {
            setShowConfirmModalDelete(false);
            ExportData();
        }}>{t('exportSieFile.label')}</Button>
        {' '}
        <Button className="button-bg-grow border-0" onClick={() => {
            //GetAccountRows();
            previewData();
            setShowConfirmModalDelete(false)
            
        }}>{t('exportSieFile.modelPreview')}</Button>
        {' '}
        <Button className="button-bg-denial border-0" onClick={() => {
            setShowConfirmModalDelete(false)
            setStartDate(defaultStartDate)
            setEndDate(null)
        }               
            }>{t('button.cancel')}</Button>
        </>
    );
 
    const togglePicker = (e) => {
    e.preventDefault();
    setShowError(false);
    setShowPicker(!showPicker);
    setShowPreviewCard(false);
    };
    
    const applyChanges = () => {
    setShowPicker(false);
    setShowConfirmModalDelete(true)    
    };  
      
    const onChange = (dates) => {
        const [start, end] = dates;
        
        const startDateSelection = new Date(start);
        startDateSelection.setHours(0);
        startDateSelection.setMinutes(1);
        startDateSelection.setSeconds(0);
        startDateSelection.setMilliseconds(0);
         
        let endDateSelection = null;
        if (end !== null) {
            endDateSelection = new Date(end);
            endDateSelection.setHours(23);
            endDateSelection.setMinutes(59);
            endDateSelection.setSeconds(59);
            endDateSelection.setMilliseconds(0);
        }
        setStartDate(startDateSelection);
        setEndDate(endDateSelection);
    };
    const handleDownloadApiResponse  = (response, name) => {
        let file;
        if (response instanceof Blob) {
          file = response;
          
        } else {
          file = new Blob([response]);
        }

        let fileName = name;

        if (!name || name.trim() === "") {
            fileName = "file.sie";
        } else {
            fileName = name;
        }
        
        
        setTimeout(() => {
          CustomReload();
        }, 1000);

        const urlToFile = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = urlToFile;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        setTimeout(() => {
          CustomReload();
        }, 2000);
    }

    const DownloadFile = async (id, fileName) => {
        let url = `/file?id=${id}`        
        const apiCallOptions = {
            method: "get",
            url: url,
            dataTypes: 'blob'
        };
        try {
            await ApiCall(apiCallOptions, (response) => handleDownloadApiResponse(response, fileName));

        } catch (error) {
            console.log(error);
        }
    };
    const renderActionColumn = (item) => {
        if((item.generated && item.generated.includes("Inga"))){
            return null
        }
        return (
            <>
                <CustomButton icon="bx bx-down-arrow-alt pb-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText='Nedladdningar' customClasses="rounded" buttonBorder={false} customClassesTooltip="tooltip-custom-button-table"
                              id={item.id}
                              onClick={() => {
                              DownloadFile(item.id, item.fileName);
                            }}

                />
            </>
        );
    }

    

    const areDatesValid = () => {
        return startDate !== null && endDate !== null;
    };
    const customLocale = CustomDateLocale();
    const columns = ["visualId", 'generated','fileName','createdAt'];

    const listOptions = {
        "model": ResponseSieModel,
        'columns': columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/export-data/admin/search",
        "nameFile": t('exportSieFile.label'),
        "ignoreFields" : columns,
        "modelType" : "ExportSie",
        "urlGetSearch" : "/export-data/admin/search",
    };
      return (
        <>
            <CustomModal isOpen={showConfirmModalDelete} toggle={() => setShowConfirmModalDelete(!showConfirmModalDelete)} body={contentBodyDelete} footer={contentFooterDelete}/>
              <Col lg="12" className='card card-backend p-4 pb-5 mb-4'>
                    <h2 className="pb-2 rokkitt-font">{t('exportSieFile.label')}</h2>
                    <div className="pb-3" >
                        <CustomButton
                            customClasses="rounded"
                            text={t('exportSieFile.label')}
                            icon="bx bx-export pe-2"
                            onClick={togglePicker}
                        />
                        {showError && (
                             <span className="pb-2" style={{ color: 'red' }}>{t('exportSieFile.modelErrorMessageExport')}</span>
                     )}
                    </div>
                    <Row>
                        <Col md="3" >
                            {showPicker && (
                                <div  style={{ marginTop: '0rem' }}>
                                    <DatePicker
                                        selected=''
                                        onChange={onChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        inline
                                        selectsRange
                                        className="w-100 border-grey-date rounded-2 padding-input-date"
                                        maxDate={today}
                                        locale={customLocale}
                                    />
                                    <div className="pb-3 ">
                                        <CustomButton
                                            customClasses="rounded"
                                            text={t('button.send')}
                                            onClick={applyChanges}
                                            disabled={!areDatesValid()}
                                        />
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
              </Col>
          {/* {showPreviewCard && (
                <Col lg="6" className='card card-backend p-4 pb-5 mb-5'>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h5 className="mb-0">{t('exportSieFile.modelPreview')} - <span className="fw-bold text-warning">{t('reportProceeding.calculations.subtitle')}</span></h5>
                        <CustomButton  customClasses="rounded pb-1"
                                onClick={() =>ExportData()}
                                text='Export'>

                        </CustomButton>
                    </div>
                    <h6>Format - PC8 </h6>
                    <h6>GEN 20240417 - <span className="fw-bold text-info">{`${userData.firstName} ${userData.lastName}`}</span></h6>
                    <h6>SIETYP - 4</h6>
                    <h6>ORGNR - 802001-9850</h6>
                    <h6>FNAMN LANTBRUKARNAS RIKSF RBUND</h6>
                    <h6>KRTYP - EUBAS97</h6>
                    <h6>VALUTA - SEK</h6>

                    {items.map((item, index) => {
                        if (item.model === "Intern transaktion") {
                            return null;
                        }
                        return (
                            <Row key={index}>
                                <Col lg="12" className="mt-2">
                                    <div className="fs-6 fw-bold">VER :</div>
                                    <div>{`DL - ${new Date(item.createdAt).toLocaleDateString()} - ${item.model} - ${item.description} - DL`}</div>
                                    <div className="box-beig" style={{ textAlign: 'left' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div className="fw-bold">TRANS - <span style={{ fontWeight: 'normal' }}>{`KONTO: 1934 Bankkonto Digitala kassan`}</span></div>
                                            <div className="fw-bold">{`Amount: ${item.amount} kr`}</div>
                                        </div>
                                        {item.dataSIE && (
                                            <>
                                            {`Kostnadsstalle: ${item.dataSIE.costPlace ? item.dataSIE.costPlace + ' - ' + (item.dataSIE.costPlaceName || '') : ''}`}<span style={{ fontWeight: 'bold' }}> | </span> {`Kostnadsbarare: ${item.dataSIE.costBearer ? item.dataSIE.costBearer + ' - ' + (item.dataSIE.costBearerName || '') : ''}`}
                                            <hr/>
                                            {item.dataSIE.accountings.map((data, index) => (

                                                <div key={index}>
                                                    { console.log('value', data.accountingAccountValue)}
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div className="fw-bold">TRANS - <span style={{ fontWeight: 'normal' }}>{`KONTO: ${data.accountingAccountValue}  ${data.accountingAccountName}`}</span></div>
                                                        <div className="fw-bold">{`Amount: ${data.amount} kr`}</div>
                                                    </div>
                                                    <div>{`Kostnadsstalle: ${item.dataSIE.costPlace ? item.dataSIE.costPlace + ' - ' + (item.dataSIE.costPlaceName || '') : ''}`} <span style={{ fontWeight: 'bold' }}> | </span>{`Kostnadsbarare: ${item.dataSIE.costBearer ? item.dataSIE.costBearer + ' - ' + (item.dataSIE.costBearerName || '') : ''}`}
                                                    </div>
                                                    {index !== item.dataSIE.accountings.length - 1 && <hr/>}
                                                </div>
                                            ))}
                                            </>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}
                </Col>
            )}*/}

           {/* {checkPreview !== false ?*/}
                    <div id='cardPreview' className="card-backend p-5 d-none">
                        <Row>
                            <Col className="col-10 d-flex">
                                <span id="spinner" className="d-none">
                                    {t('validationMessage.loadingSieData')} <Spinner color='success' className="ms-2" />
                                </span>
                            </Col>
                            <Col className="col-2 text-end">
                                {
                                    newFormat !== '' ?
                                        <CustomButton  customClasses="rounded pb-1"
                                                       onClick={() => {
                                                           ExportData();
                                                           $("#cardPreview").addClass("d-none");
                                                           setFormat('');
                                                       }
                                        }
                                                       text='Export'>
                                        </CustomButton>
                                        : ''
                                }
                            </Col>
                        </Row>
                        <div id='contentPreview' className='d-none'>
                           {newFormat !== '' ?
                                <div
                                    dangerouslySetInnerHTML={{ __html: newFormat }}
                                />
                            : t('exportSieFile.periodNotFound') }
                        </div>
                    </div>

                    {/*: ''*/}

            <Col lg={12} className="card card-backend p-4 pb-5 mb-5 mt-4">
                <SortableList
                    listOptions={listOptions}
                />
            </Col>
        </>
    );
};

export default AdminExportSIE;